import { render, staticRenderFns } from "./CompraMateriaPrima.vue?vue&type=template&id=4ffd1e73&scoped=true&"
import script from "./CompraMateriaPrima.vue?vue&type=script&lang=js&"
export * from "./CompraMateriaPrima.vue?vue&type=script&lang=js&"
import style0 from "./CompraMateriaPrima.vue?vue&type=style&index=0&id=4ffd1e73&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ffd1e73",
  null
  
)

export default component.exports